import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));

// CENSUS
export const PreCensusLandingPage = Loadable(lazy(() => import('../pages/census/PreCensusLandingPage')));
export const CensusPage = Loadable(lazy(() => import('../pages/census/CensusPage')));
export const AfterSubmitCensusPage = Loadable(lazy(() => import('../pages/census/AfterSubmitCensusPage')));
export const CharteredCensusPage = Loadable(lazy(() => import('../pages/census/CharteredCensusPage')));
export const YorePremiumCensusPage = Loadable(lazy(() => import('../pages/census/YorePremiumCensusPage')));
export const AfterSubmitCharteredCensusPage = Loadable(lazy(() => import('../pages/census/AfterSubmitCharteredCensusPage')));
export const AfterSubmitYorePremiumCensusPage = Loadable(lazy(() => import('../pages/census/AfterSubmitYorePremiumCensusPage')));
export const CensusAdminPage = Loadable(lazy(() => import('../pages/census/CensusAdminPage')));
export const CensusProfilePage = Loadable(lazy(() => import('../pages/census/CensusProfilePage')));
export const CensusEmailCreatedPage = Loadable(lazy(() => import('../pages/census/CensusEmailCreatedPage')));

// PROFILE
export const OwnProfilePage = Loadable(lazy(() => import('../pages/profiles/ProfileEditPage')));
export const PublishOwnProfilePage = Loadable(lazy(() => import('../pages/profiles/ProfileFieldsPublishPage')));
export const OwnMembershipManagementPage = Loadable(lazy(() => import('../pages/profiles/ProfileMembershipManagementPage')));
export const AllProfilesPage = Loadable(lazy(() => import('../pages/ProfileListPage')));
export const AllProfilesPage2 = Loadable(lazy(() => import('../pages/ProfileListPage2')));
export const ProfilePage = Loadable(lazy(() => import('../pages/ProfilePage')));

// ELECTION
export const ElectionLandingPage = Loadable(lazy(() => import('../pages/election/ElectionLandingPage')));
export const ElectionPage = Loadable(lazy(() => import('../pages/election/ElectionPage')));
export const PublicElectionPage = Loadable(lazy(() => import('../pages/election/PublicElectionPage')));
export const ElectionResult = Loadable(lazy(() => import('../pages/election/ElectionResultPage')));

// CLUSTER
export const ClusterAdminPage = Loadable(lazy(() => import('../pages/cluster/ClusterAdminPage')));
export const ClusterProfilePage = Loadable(lazy(() => import('../pages/cluster/ClusterProfilePage')));

export const PageOne = Loadable(lazy(() => import('../pages/PageOne')));
export const PageTwo = Loadable(lazy(() => import('../pages/PageTwo')));
export const PageThree = Loadable(lazy(() => import('../pages/PageThree')));
export const PageFour = Loadable(lazy(() => import('../pages/PageFour')));
export const PageFive = Loadable(lazy(() => import('../pages/PageFive')));
export const PageSix = Loadable(lazy(() => import('../pages/PageSix')));

export const GrammarlyPage = Loadable(lazy(() => import('../pages/facilities/GrammarlyPage')));
export const IdCardPage = Loadable(lazy(() => import('../pages/facilities/IdCardPage')));

export const RoleManagementPage = Loadable(lazy(() => import('../pages/admin/RoleManagementPage')));
export const SuperAdminPage = Loadable(lazy(() => import('../pages/admin/SuperAdminPage')));
export const PaidMembershipsAdminPage = Loadable(lazy(() => import('../pages/admin/PaidMembershipsAdminPage')));

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const PageUserNotVerified = Loadable(lazy(() => import('../pages/PageUserNotVerified')));
