import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  Page404,
  PageOne,
  PageTwo,
  PageSix,
  PageFour,
  PageFive,
  LoginPage,
  PageThree,
  OwnProfilePage, PublishOwnProfilePage,
  AllProfilesPage,
  AllProfilesPage2,
  ProfilePage,
  CensusPage,
  CharteredCensusPage,
  YorePremiumCensusPage,
  AfterSubmitCensusPage,
  AfterSubmitCharteredCensusPage,
  AfterSubmitYorePremiumCensusPage,
  PreCensusLandingPage,
  CensusAdminPage, PageUserNotVerified,
  CensusProfilePage, GrammarlyPage, RoleManagementPage, CensusEmailCreatedPage,
  SuperAdminPage,
  ElectionPage, ElectionLandingPage, PublicElectionPage,
  OwnMembershipManagementPage, PaidMembershipsAdminPage, IdCardPage, ClusterProfilePage,
} from './elements';
import PublicLayout from "../layouts/public";
import ElectionResultPage from "../pages/election/ElectionResultPage";
import ClusterAdminPage from "../pages/cluster/ClusterAdminPage";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/census',
      element: <PublicLayout />,
      children: [
        { element: <Navigate to="/census/new" replace />, index: true },
        { path: 'coming-soon', element: <PreCensusLandingPage />},
        { path: 'new', element: <CensusPage />},
        { path: ':id/thanks', element: <AfterSubmitCensusPage />},
        { path: ':id', element: <CensusPage />},
        { path: ':id/chartered', element: <CharteredCensusPage />},
        { path: ':id/chartered/thanks', element: <AfterSubmitCharteredCensusPage />},
        { path: ':id/yore-premium', element: <YorePremiumCensusPage />},
        { path: ':id/yore-premium/thanks', element: <AfterSubmitYorePremiumCensusPage />},
        { path: ':id/email-created', element: <CensusEmailCreatedPage />},
      ]
    },
    {
      path: '/election',
      element: <PublicLayout />,
      children: [
          { element: <ElectionLandingPage />, index: true },
          { path: 'vote/:id', element: <PublicElectionPage />},
        ]
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'profile',
          children: [
            { element: <Navigate to="/dashboard/profile/me" replace />, index: true },
            { path: 'me', element: <OwnProfilePage/> },
            { path: 'public-fields', element: <PublishOwnProfilePage/>},
            { path: 'membership-management', element: <OwnMembershipManagementPage />},
            // { path: 'all', element: <AllProfilesPage /> },
            // { path: 'all2', element: <AllProfilesPage2 /> },
            { path: ':id', element: <ProfilePage /> },
            { path: 'id-card', element: <IdCardPage /> },
            { path: '*', element: <Navigate to="/404" replace />}
          ],
        },
        {
          path: 'census',
          children: [
            { element: <Navigate to="/dashboard/census/admin" replace />, index: true },
            { path: 'admin', element: <CensusAdminPage /> },
            { path: ':id', element: <CensusProfilePage /> },
            { path: '*', element: <Navigate to="/404" replace />}
          ],
        },
        {
          path: 'election',
          children: [
              // { element: <Navigate to="/dashboard/election/vote" replace />, index: true },
            { element: <Navigate to="/404" replace />, index: true },
            { path: 'vote', element: <ElectionPage /> },
            {path: 'result', element: <ElectionResultPage/>},
            { path: '*', element: <Navigate to="/404" replace />}
          ],
        },
        { path: 'facilities',
          children: [
            { element: <Navigate to="/dashboard/facilities/1" replace />, index: true },
            { path: 'grammarly', element: <GrammarlyPage /> },
            { path: '*', element: <Navigate to="/404" replace />}
              ],
        },
        {
          path: 'admin',
          children: [
            {path: 'roles', element: <RoleManagementPage/>},
            {path: 'super-admin', element: <SuperAdminPage/>},
            {path: 'paid-member-admin', element: <PaidMembershipsAdminPage/>},
            { path: '*', element: <Navigate to="/404" replace />}
          ],
        },
        {
          path: 'cluster',
          children: [
            { element: <Navigate to="/dashboard/cluster/admin" replace />, index: true },
            { path: 'admin', element: <ClusterAdminPage /> },
            { path: ':id', element: <ClusterProfilePage /> }
          ],
        }

        // { path: 'one', element: <PageOne /> },
        // { path: 'two', element: <PageTwo /> },
        // { path: 'three', element: <PageThree /> },
        // {
        //   path: 'user',
        //   children: [
        //     { element: <Navigate to="/dashboard/user/four" replace />, index: true },
        //     { path: 'four', element: <PageFour /> },
        //     { path: 'five', element: <PageFive /> },
        //     { path: 'six', element: <PageSix /> },
        //   ],
        // },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
          { path: '404', element: <Page404 /> },
          { path: 'not-verified', element: <PageUserNotVerified /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
