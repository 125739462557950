import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Box, IconButton, Tooltip} from "@mui/material";
import HubIcon from "@mui/icons-material/Hub";

import axios from "../../../utils/axios";
import {PATH_DASHBOARD} from "../../../routes/paths";


export default function ClusterTable() {
    const navigate = useNavigate();
    const [clusterData, setClusterData] = useState([]);

    useEffect(() => {
        axios.get(`/api/clusters/all/active`, {withCredentials: false}).then((res => {
            setClusterData(res.data.data);
        }));
    }, []);

    const columns = useMemo(() => [
        {
            accessorKey: 'displayName',
            header: 'Cluster Name',
        },
        {
            accessorKey: 'coordinators',
            header: 'Coordinators',
            Cell: ({ cell }) => {
                const coordinators = cell.getValue()|| [];
                return (
                    <ul>
                        {coordinators.map((x) => (
                            <li key={x.memberId}>
                                {x.givenName} {x.familyName}
                            </li>
                        ))}
                    </ul>
                );
            },
        }
    ], []);


    const table = useMaterialReactTable({
        columns,
        data: clusterData,
        enableTableHead: true,
        enableGlobalFilter: false,
        muiTableHeadCellProps: {
        //simple styling with the `sx` prop, works just like a style prop in this example
            sx: {
                backgroundColor: (theme) => theme.palette.grey[200],
                    borderRight: '2px solid #e0e0e0', //add a border between columns
            },
        },
        enableRowActions: true,
        renderRowActions: ({ row, t }) => (
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                <Tooltip title="Open Cluster Profile">
                    <IconButton
                        color="primary"
                        onClick={() =>
                            navigate(PATH_DASHBOARD.cluster.profile(row.original.nameId))
                        }
                    >
                        <HubIcon />
                    </IconButton>
                </Tooltip>

            </Box>
        )
    });

    return (
        <MaterialReactTable
            table={table}
        />
    );
}
