import {Helmet} from "react-helmet-async";
import {Box, Card, Container} from "@mui/material";


import RoleBasedGuard from "../../auth/RoleBasedGuard";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import {PATH_DASHBOARD} from "../../routes/paths";
import {useSettingsContext} from "../../components/settings";
import ClusterTable from "../../sections/@dashboard/cluster/ClusterTable";


export default function ClusterAdminPage() {

    const { themeStretch } = useSettingsContext();

    return (
        <>
            <Helmet>
                <title> Cluster Admin | Doctrine UK</title>
            </Helmet>

            <RoleBasedGuard hasContent roles={['superAdmin', 'clusterAdmin']}>
                <Container maxWidth={themeStretch ? false : 'lg'}>
                    <CustomBreadcrumbs
                        heading="Cluster Admin"
                        links={[
                            { name: 'Dashboard', href: PATH_DASHBOARD.root },
                            { name: 'Cluster Admin' },
                        ]}
                    />

                    <Card>
                        <Box sx={{ width: '100%' }} m={3}>
                            <Box mr={5}>
                                <ClusterTable />
                            </Box>
                        </Box>


                    </Card>
                </Container>
            </RoleBasedGuard>
        </>
    );
}
